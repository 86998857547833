import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const HeartLink = styled(Link)`
    text-decoration: none;
`;

export const HeartWrapper = styled.div<{ isMobile?: boolean }>(
    ({ theme: { colors }, isMobile }) => css`
        display: flex;
        position: relative;
        stroke: ${isMobile ? colors.white : colors.neutral900};
        width: 32px;

        &:hover {
            svg {
                stroke: ${colors.primaryBlue500};
            }
        }
    `,
);

export const Number = styled.div<{ isMobile?: boolean }>(
    ({ theme: { colors }, isMobile }) => css`
        align-items: center;
        background-color: ${isMobile ? colors.white : colors.primaryBlue500};
        border-radius: 50%;
        border: 1.5px solid ${isMobile ? colors.neutral900 : colors.white};
        color: ${isMobile ? colors.primaryBlue500 : colors.white};
        display: flex;
        font-size: 10px;
        height: ${isMobile ? '18' : '22'}px;
        justify-content: center;
        position: absolute;
        right: ${isMobile ? '0' : '-11'}px;
        top: 0;
        width: ${isMobile ? '18' : '22'}px;
    `,
);
