import styled, { css } from 'styled-components';
import { SectionText, SectionWrapper } from 'styles/Typography.styled';
import { mediaQueries } from 'shared/breakpoints';

export const Container = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        background: ${colors.primaryBlue200};
        display: flex;
        flex-direction: column;
        overflow-x: clip;
        padding: 0px 20px;
        position: relative;

        ${mediaQueries.xs} {
            padding: 0px;
        }
    `,
);

export const SectionDescription = styled(SectionText)(
    ({ theme: { colors } }) => css`
        color: ${colors.primaryBlue50};
        font-weight: 500;
        margin: 0;
        max-width: 450px;

        ${mediaQueries.xs} {
            margin-bottom: 41px;
            max-width: 295px;
            text-align: center;
        }
    `,
);

export const StyledSectionWrapper = styled(SectionWrapper)<{
    imageUrl: string;
}>(
    ({ imageUrl }) => css`
        align-items: flex-end;
        background: linear-gradient(
                164.26deg,
                rgba(32, 38, 59, 0) 0.13%,
                rgba(32, 38, 59, 0.120757) 46.29%,
                rgba(32, 38, 59, 0.2) 89.01%
            ),
            url(${imageUrl});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: space-between;
        margin: 88px 0;
        min-height: 353px;
        overflow: hidden;
        padding: 85px 100px;
        position: relative;
        z-index: 3;

        ${mediaQueries.xs} {
            align-items: center;
            background-size: cover;
            flex-direction: column;
            justify-content: flex-end;
            margin: 58px 16px 56px;
            min-height: 407px;
            padding: 70px 24px;
        }
    `,
);

export const TableWrapper = styled.div`
    margin-bottom: 56px;
`;

export const HouseIcon = styled.img`
    bottom: 88px;
    pointer-events: none;
    position: absolute;
    width: 100%;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const Sentence = styled.p`
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    left: 50%;
    line-height: 54px;
    position: absolute;
    transform: translateX(-50%);
    width: 1731px;

    ${mediaQueries.sm} {
        font-size: 12px;
        width: 520px;
    }
`;

export const WhiteSentence = styled(Sentence)(
    ({ theme: { colors } }) => css`
        color: ${colors.white};
        top: 96px;
        z-index: 4;

        ${mediaQueries.sm} {
            top: 62px;
        }
    `,
);

export const BlackSentence = styled(Sentence)(
    ({ theme: { colors } }) => css`
        color: ${colors.black};
        top: 184px;
        z-index: 2;

        ${mediaQueries.sm} {
            top: 120px;
        }
    `,
);
