import { Links } from 'shared/links';

export const getDisabledPages = (investment: any) => {
    const disabledPages = [];
    if (!investment?.enablePromotionsPage) {
        disabledPages.push('/promocje');
    }
    if (!investment?.enableSafeCreditPage) {
        disabledPages.push('/bezpieczny-kredyt');
    }
    if (!investment?.enablePartnersPage) {
        disabledPages.push('/partnerzy');
    }
    return disabledPages;
};

export const getMenuLinks = (disabledPages?: string[]) => {
    return Links.reduce((acc: typeof Links, item) => {
        if (!disabledPages || !disabledPages.includes(item.link)) {
            acc.push(item);
        }
        return acc;
    }, []);
};
