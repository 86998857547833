import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { HeaderHeart, MobileHeart } from 'shared/svg/hearts';
import { HeartLink, HeartWrapper, Number } from './HeartFavouriteFlats.styled';

interface HeartFavouriteFlatsProps {
    investmentSlug?: string;
    isMobile?: boolean;
}

export const HeartFavouriteFlats = ({
    investmentSlug,
    isMobile,
}: HeartFavouriteFlatsProps) => {
    const { liked } = useTheme();

    return (
        <HeartLink to={'/' + investmentSlug + '/ulubione'}>
            <HeartWrapper isMobile={isMobile}>
                {!isMobile && <HeaderHeart />}
                {isMobile && <MobileHeart />}
                {!!liked.length && (
                    <Number isMobile={isMobile}>{liked.length}</Number>
                )}
            </HeartWrapper>
        </HeartLink>
    );
};
