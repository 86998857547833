import { HeartFavouriteFlats } from 'components/_layout/HeartFavouriteFlats/HeartFavouriteFlats';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { Links } from 'shared/links';
import { ImageType } from 'types/CommonTypes';
import {
    BurgerButton,
    BurgerMenuContent,
    BurgerMenuWrapper,
    IconLine,
    KontaktLink,
    KontaktWrapper,
    MobileLogo,
    NavContainer,
    NavLink,
    NavLinkfavorite,
    StyledLink,
} from './BurgerMenu.styled';
import { getMenuLinks } from 'utils/utils';

const isBrowser = typeof window !== 'undefined';

export interface BurgerMenuProps {
    addressLink: string;
    footerAddress: string;
    footerMail: { emailAddress: string }[];
    footerPhones: { phoneNumber: string }[];
    headerMobileLogo: ImageType;
    investmentSlug?: string;
}

export const BurgerMenu = ({
    addressLink,
    footerAddress,
    footerMail,
    footerPhones,
    headerMobileLogo,
    investmentSlug,
}: BurgerMenuProps) => {
    const { mobileMenuIsOpen, setMobileMenuIsOpen, disabledPages } = useTheme();

    const isCurrent = (name: string[]) => {
        if (isBrowser) {
            const path = window.location.pathname.split('/');
            return name.includes(path[1]);
        }
    };

    return (
        <BurgerMenuWrapper>
            <BurgerButton
                aria-label="burger-button"
                isOpen={mobileMenuIsOpen}
                onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
            >
                <IconLine />
                <IconLine />
                <IconLine />
            </BurgerButton>
            <BurgerMenuContent isOpen={mobileMenuIsOpen}>
                <StyledLink to={'/' + investmentSlug}>
                    <MobileLogo
                        alt={headerMobileLogo?.altText}
                        src={headerMobileLogo?.sourceUrl}
                    />
                </StyledLink>
                <NavContainer>
                    {getMenuLinks(disabledPages).map(
                        ({ link, name, key }, index) => (
                            <NavLink
                                key={index}
                                to={'/' + investmentSlug + link}
                                isActive={isCurrent(key)}
                            >
                                {name}
                            </NavLink>
                        ),
                    )}
                    <NavLinkfavorite to={'/' + investmentSlug + '/ulubione'}>
                        Ulubione <HeartFavouriteFlats isMobile />
                    </NavLinkfavorite>
                </NavContainer>
                <KontaktWrapper>
                    <KontaktLink href={addressLink} target={'blank'}>
                        <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                        >
                            <path d="M13.7467 5.85143C13.0467 2.77143 10.3601 1.38477 8.00006 1.38477C8.00006 1.38477 8.00006 1.38477 7.9934 1.38477C5.64006 1.38477 2.94673 2.76477 2.24673 5.84476C1.46673 9.28476 3.5734 12.1981 5.48006 14.0314C6.18673 14.7114 7.08673 15.8181 7.9934 15.8181C8.90006 15.8181 9.8134 14.7114 10.5134 14.0314C12.4201 12.1981 14.5267 9.29143 13.7467 5.85143ZM8.00006 9.19143C6.84006 9.19143 5.90006 8.25143 5.90006 7.09143C5.90006 5.93143 6.84006 4.99143 8.00006 4.99143C9.16006 4.99143 10.1001 5.93143 10.1001 7.09143C10.1001 8.25143 9.16006 9.19143 8.00006 9.19143Z" />
                        </svg>
                        {footerAddress}
                    </KontaktLink>
                    {footerMail?.map((item, index) => (
                        <KontaktLink
                            href={'mailto:' + item.emailAddress}
                            key={index}
                        >
                            <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                            >
                                <path d="M11.334 2.55176H4.66732C2.66732 2.55176 1.33398 3.55176 1.33398 5.88509V10.5518C1.33398 12.8851 2.66732 13.8851 4.66732 13.8851H11.334C13.334 13.8851 14.6673 12.8851 14.6673 10.5518V5.88509C14.6673 3.55176 13.334 2.55176 11.334 2.55176ZM11.6473 6.61176L9.56065 8.27842C9.12065 8.63176 8.56065 8.80509 8.00065 8.80509C7.44065 8.80509 6.87398 8.63176 6.44065 8.27842L4.35398 6.61176C4.14065 6.43842 4.10732 6.11842 4.27398 5.90509C4.44732 5.69176 4.76065 5.65176 4.97398 5.82509L7.06065 7.49176C7.56732 7.89842 8.42732 7.89842 8.93398 7.49176L11.0207 5.82509C11.234 5.65176 11.554 5.68509 11.7207 5.90509C11.894 6.11842 11.8607 6.43842 11.6473 6.61176Z" />
                            </svg>
                            {item.emailAddress}
                        </KontaktLink>
                    ))}
                    {footerPhones?.map((item, index) => (
                        <KontaktLink
                            href={'tel:' + item.phoneNumber}
                            key={index}
                        >
                            {index === 0 && (
                                <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                >
                                    <path d="M7.36732 10.1851L6.13398 11.4184C5.87398 11.6784 5.46065 11.6784 5.19398 11.4251C5.12065 11.3518 5.04732 11.2851 4.97398 11.2118C4.28732 10.5184 3.66732 9.79176 3.11398 9.03176C2.56732 8.27176 2.12732 7.51176 1.80732 6.75842C1.49398 5.99843 1.33398 5.27176 1.33398 4.57842C1.33398 4.12509 1.41398 3.69176 1.57398 3.29176C1.73398 2.88509 1.98732 2.51176 2.34065 2.17842C2.76732 1.75842 3.23398 1.55176 3.72732 1.55176C3.91398 1.55176 4.10065 1.59176 4.26732 1.67176C4.44065 1.75176 4.59398 1.87176 4.71398 2.04509L6.26065 4.22509C6.38065 4.39176 6.46732 4.54509 6.52732 4.69176C6.58732 4.83176 6.62065 4.97176 6.62065 5.09842C6.62065 5.25842 6.57398 5.41842 6.48065 5.57176C6.39398 5.72509 6.26732 5.88509 6.10732 6.04509L5.60065 6.57176C5.52732 6.64509 5.49398 6.73176 5.49398 6.83842C5.49398 6.89176 5.50065 6.93842 5.51398 6.99176C5.53398 7.04509 5.55398 7.08509 5.56732 7.12509C5.68732 7.34509 5.89398 7.63176 6.18732 7.97843C6.48732 8.32509 6.80732 8.67842 7.15398 9.03176C7.22065 9.09842 7.29398 9.16509 7.36065 9.23176C7.62732 9.49176 7.63398 9.91842 7.36732 10.1851Z" />
                                    <path d="M14.6471 12.4381C14.6471 12.6248 14.6137 12.8181 14.5471 13.0048C14.5271 13.0581 14.5071 13.1115 14.4804 13.1648C14.3671 13.4048 14.2204 13.6315 14.0271 13.8448C13.7004 14.2048 13.3404 14.4648 12.9337 14.6315C12.9271 14.6315 12.9204 14.6381 12.9137 14.6381C12.5204 14.7981 12.0937 14.8848 11.6337 14.8848C10.9537 14.8848 10.2271 14.7248 9.46039 14.3981C8.69372 14.0715 7.92706 13.6315 7.16706 13.0781C6.90706 12.8848 6.64706 12.6915 6.40039 12.4848L8.58039 10.3048C8.76706 10.4448 8.93372 10.5515 9.07372 10.6248C9.10706 10.6381 9.14706 10.6581 9.19372 10.6781C9.24706 10.6981 9.30039 10.7048 9.36039 10.7048C9.47372 10.7048 9.56039 10.6648 9.63372 10.5915L10.1404 10.0915C10.3071 9.92479 10.4671 9.79813 10.6204 9.71813C10.7737 9.62479 10.9271 9.57812 11.0937 9.57812C11.2204 9.57812 11.3537 9.60479 11.5004 9.66479C11.6471 9.72479 11.8004 9.81146 11.9671 9.92479L14.1737 11.4915C14.3471 11.6115 14.4671 11.7515 14.5404 11.9181C14.6071 12.0848 14.6471 12.2515 14.6471 12.4381Z" />
                                </svg>
                            )}
                            {item.phoneNumber}
                        </KontaktLink>
                    ))}
                </KontaktWrapper>
            </BurgerMenuContent>
        </BurgerMenuWrapper>
    );
};
