import HeaderLogo from 'images/logo-real.png';
import React from 'react';
import { ImageType } from 'types/CommonTypes';
import { BurgerMenu } from '../BurgerMenu/BurgerMenu';
import { HeartFavouriteFlats } from '../HeartFavouriteFlats/HeartFavouriteFlats';
import { Navigation } from '../Navigation/Navigation';
import {
    BurgerWrapper,
    HeaderWrapper,
    MobileLogo,
    SecondHeaderLogo,
    StyledLink,
    Logo,
} from './Header.styled';

export interface HeaderProps {
    addressLink: string;
    footerAddress: string;
    footerLogo: ImageType;
    footerMail: { emailAddress: string }[];
    footerPhones: { phoneNumber: string }[];
    headerDesktopLogo: ImageType;
    headerMobileLogo: ImageType;
    investmentSlug?: string;
    menuPosition: string;
}

export const Header = ({
    addressLink,
    footerAddress,
    footerLogo,
    footerMail,
    footerPhones,
    headerDesktopLogo,
    headerMobileLogo,
    investmentSlug,
    menuPosition,
}: HeaderProps) => {
    return (
        <HeaderWrapper>
            <StyledLink to={'/' + investmentSlug}>
                <Logo
                    alt={headerDesktopLogo?.altText}
                    src={headerDesktopLogo?.sourceUrl}
                />
                <MobileLogo
                    alt={headerDesktopLogo?.altText}
                    src={headerDesktopLogo?.sourceUrl}
                />
            </StyledLink>
            <Navigation
                investmentSlug={investmentSlug}
                menuPosition={menuPosition}
            />
            <SecondHeaderLogo src={HeaderLogo} />
            <BurgerWrapper>
                <HeartFavouriteFlats investmentSlug={investmentSlug} />
                <BurgerMenu
                    addressLink={addressLink}
                    footerAddress={footerAddress}
                    footerMail={footerMail}
                    footerPhones={footerPhones}
                    headerMobileLogo={footerLogo}
                    investmentSlug={investmentSlug}
                />
            </BurgerWrapper>
        </HeaderWrapper>
    );
};
