import Head from 'components/Head';
import { ToastNotification } from 'components/ToastNotification/ToastNotification';
import { Footer } from 'components/_layout/Footer/Footer';
import { Header } from 'components/_layout/Header/Header';
import React, { ReactChild, useEffect, useState } from 'react';
import { StreetAddressAndImage } from 'sections/StreetAddressAndImage/StreetAddressAndImage';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/GlobalStyles.styled';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { Theme } from 'types/CommonTypes';
import themeVariant from 'utils/themeVariant';
import { ThemeContext } from './ThemeContext';

interface ILayoutProps {
    children: ReactChild;
    displayBanner?: boolean;
    displayPartnersPage?: boolean;
    headerData?: any;
    investmentSlug?: string;
    pageTitle?: string;
    location: {
        pathname: string;
    };
    investmentDisabledPages?: string[];
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
`;

export default ({
    children,
    displayBanner,
    displayPartnersPage,
    headerData,
    investmentSlug,
    location,
    pageTitle,
    investmentDisabledPages,
}: ILayoutProps) => {
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
    const [liked, setLiked] = useState<string[]>([]);
    const [tooltipActive, setTooltipActive] = useState(false);
    const [building, setBuilding] = useState<string[]>([]);
    const [floor, setFloor] = useState<string[]>([]);
    const [room, setRoom] = useState<string[]>([]);
    const [yardage, setYardage] = useState<string[]>([]);
    const [price, setPrice] = useState<string[]>([]);
    const [facilities, setFacilities] = useState<string[]>([]);
    const [sortBy, setSortBy] = useState<string>('Domyślnie');
    const [status, setStatus] = useState<string[]>([]);
    const [isInitial, setIsInitial] = useState(true);
    const [numberFilter, setNumberFilter] = useState(0);
    const [activeFilter, setActiveFilter] = useState(false);
    const [partnersPageVisible, setPartnersPageVisible] =
        useState(displayPartnersPage);
    const [disabledPages, setDisabledPages] = useState(investmentDisabledPages);
    const data = headerData?.components;

    useEffect(() => {
        investmentSlug && localStorage.setItem('real-slug', investmentSlug);
        const getLocalStorageLiked = localStorage.getItem(
            'real-favorite-' + investmentSlug,
        ) as string;
        if (getLocalStorageLiked !== null) {
            setLiked(JSON.parse(getLocalStorageLiked));
        }
    }, []);

    return (
        <Wrapper>
            <ThemeContext.Provider
                value={{
                    liked,
                    mobileMenuIsOpen,
                    setLiked,
                    setMobileMenuIsOpen,
                    setTooltipActive,
                    tooltipActive,
                    floor,
                    setFloor,
                    building,
                    setBuilding,
                    room,
                    setRoom,
                    yardage,
                    setYardage,
                    price,
                    setPrice,
                    facilities,
                    setFacilities,
                    sortBy,
                    setSortBy,
                    isInitial,
                    setIsInitial,
                    numberFilter,
                    setNumberFilter,
                    activeFilter,
                    setActiveFilter,
                    partnersPageVisible,
                    setPartnersPageVisible,
                    disabledPages,
                    setDisabledPages,
                    status,
                    setStatus,
                }}
            >
                <GlobalStyles mobileMenuIsOpen={mobileMenuIsOpen} />
                <Head
                    investmentSettings={headerData?.investmentSettings}
                    title={pageTitle}
                    pathname={location.pathname}
                />
                <ThemeProvider theme={themeVariant[Theme.DEFAULT]}>
                    <iframe
                        src="https://www.googletagmanager.com/ns.html?id=GTM-5Q62TK9"
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                    ></iframe>
                    <Header
                        addressLink={data?.investmentFooter?.addressLink}
                        footerAddress={data?.investmentFooter?.address}
                        footerLogo={data?.investmentFooter?.logo}
                        footerMail={data?.investmentFooter?.contactEmails}
                        footerPhones={data?.investmentFooter?.contactPhones}
                        headerDesktopLogo={data?.investmentHeader?.desktopLogo}
                        headerMobileLogo={data?.investmentHeader?.mobileLogo}
                        investmentSlug={investmentSlug}
                        menuPosition={
                            data?.investmentHeader?.menuPosition ?? 'left'
                        }
                    />
                    <ToastNotification />
                    {children}
                    {displayBanner && (
                        <StreetAddressAndImage
                            data={data?.contactBanner}
                            investmentSlug={investmentSlug}
                        />
                    )}
                    <Footer
                        addressLink={data?.investmentFooter?.addressLink}
                        footerAddress={data?.investmentFooter?.address}
                        footerLogo={data?.investmentFooter?.logo}
                        footerMail={data?.investmentFooter?.contactEmails}
                        footerPhones={data?.investmentFooter?.contactPhones}
                        shortDescription={
                            data?.investmentFooter?.shortDescription
                        }
                        investmentSlug={investmentSlug}
                    />
                </ThemeProvider>
            </ThemeContext.Provider>
        </Wrapper>
    );
};
