import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const NavContainer = styled.nav<{ isRight?: boolean }>(
    ({ isRight }) =>
        css`
            display: flex;
            justify-content: ${isRight ? 'flex-end' : 'flex-start'};
            margin: ${isRight ? '0 52px 0 auto' : 0};
            max-width: 848px;
            padding: 0 20px;
            width: 100%;

            ${isRight &&
            css`
                ${mediaQueries.lg} {
                    margin: 0 20px 0 0;
                }
            `}

            @media (max-width: 1200px) {
                display: none;
            }
        `,
);

export const NavLink = styled(Link)<{ isActive?: boolean }>(
    ({ theme: { colors, fontSizes, fontWeight }, isActive }) =>
        css`
            border-bottom: 2px solid transparent;
            color: ${colors.neutral500};
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.medium};
            line-height: 28px;
            margin: 0 16px;
            padding: 0;
            text-decoration: none;
            letter-spacing: 0.2px;
            border-bottom-color: ${isActive
                ? colors.primaryBlue500
                : 'transparent'};

            &:hover {
                color: ${colors.primaryBlue500};
            }

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        `,
);
