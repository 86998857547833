import House from 'images/house.svg';
import React from 'react';
import { TransparentButton } from 'styles/Typography.styled';
import { ImageType, LinkType } from 'types/CommonTypes';
import {
    BlackSentence,
    Container,
    HouseIcon,
    SectionDescription,
    StyledSectionWrapper,
    WhiteSentence,
} from './StreetAddressAndImage.styled';

export interface StreetAddressAndImageProps {
    data: {
        description: string;
        image: ImageType;
        link: LinkType;
        title: string;
    };
    investmentSlug?: string;
}

export const StreetAddressAndImage = ({
    data,
    investmentSlug,
}: StreetAddressAndImageProps) => {
    return (
        <Container>
            <StyledSectionWrapper imageUrl={data?.image?.sourceUrl}>
                <WhiteSentence>{data?.title}</WhiteSentence>
                <SectionDescription>{data?.description}</SectionDescription>
                <TransparentButton to={'/' + investmentSlug + '/kontakt'}>
                    Skontaktuj się z nami
                </TransparentButton>
            </StyledSectionWrapper>
            <BlackSentence>{data?.title}</BlackSentence>
            <HouseIcon src={House} />
        </Container>
    );
};
