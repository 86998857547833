import { Link } from 'gatsby';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H4, HeroText } from 'styles/Typography.styled';

export const FooterWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background-color: ${colors.neutral900};
            padding: 72px 136px;

            ${mediaQueries.md} {
                padding: 72px 80px;
            }
            ${mediaQueries.sm} {
                padding: 48px 28px;
            }
        `,
);

export const InnerWrapper = styled.div(
    ({ theme: { containerWidth } }) =>
        css`
            display: flex;
            margin: 0 auto;
            max-width: ${containerWidth.defalut};

            ${mediaQueries.sm} {
                flex-direction: column;
            }
        `,
);

export const InnerWrapperLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 470px;
`;

export const InnerWrapperRight = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    ${mediaQueries.md} {
        flex-direction: column;
        margin-left: 40px;
    }
    ${mediaQueries.sm} {
        margin-left: 0;
    }
`;

export const FooterTitle = styled(H4)(
    ({ theme: { colors, fontSizes } }) =>
        css`
            color: ${colors.primaryBlue60};
            font-size: ${fontSizes.f18};
            margin-bottom: 24px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f16};
            }
        `,
);

export const FooterText = styled(HeroText)(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.primaryBlue60};
            margin: 40px 0 54px;
            line-height: 28px;
        `,
);

export const StyledLink = styled(Link)`
    text-decoration: none;
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &:first-of-type {
        margin-right: 112px;
    }

    ${mediaQueries.md} {
        &:first-of-type {
            margin-right: 0;
            margin-bottom: 40px;
        }
    }
`;

const styledLink = css(
    ({ theme: { colors } }) =>
        css`
            align-items: center;
            border-bottom: 2px solid transparent;
            color: ${colors.primaryBlue60};
            display: flex;
            line-height: 28px;
            width: fit-content;
        `,
);

export const FooterLink = styled.a(
    ({ theme: { colors } }) =>
        css`
            ${styledLink}
            margin-bottom: 16px;
            &:last-child {
                margin-left: 40px;
            }
            svg {
                margin-right: 16px;
            }

            &:hover,
            &:focus {
                color: ${colors.primaryBlue500};
            }
        `,
);

export const FooterMenuLink = styled(Link)<{ isActive?: boolean }>(
    ({ theme: { colors, letterSpacing, fontWeight }, isActive }) =>
        css`
            ${styledLink}
            letter-spacing: ${letterSpacing.s};
            font-weight: ${fontWeight.medium};
            margin-bottom: 16px;
            border-bottom-color: ${isActive
                ? colors.primaryBlue500
                : 'transparent'};

            &:last-child {
                margin-bottom: 0px;
            }

            &:hover,
            &:focus {
                color: ${colors.primaryBlue500};
            }
        `,
);

export const FooterLogo = styled.img``;

export const SecondFooterLogo = styled.img`
    margin-top: 24px;
    max-width: 50%;
`;

export const FooterCopyRightStyle = css(
    ({ theme: { colors, fontSizes, letterSpacing } }) =>
        css`
            color: ${colors.primaryBlue60};
            font-size: ${fontSizes.f12};
            letter-spacing: ${letterSpacing.m};
            line-height: 20px;
        `,
);

export const FooterCopyRightDesktop = styled.div`
    ${FooterCopyRightStyle}
    display: block;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const FooterCopyRightMobile = styled.div`
    ${FooterCopyRightStyle}
    display: none;
    margin-top: 40px;

    ${mediaQueries.sm} {
        display: block;
    }
`;

export const FooterCopyRightTextWrapper = styled.div`
    margin-bottom: 10px;
`;
