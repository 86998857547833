import { Link } from 'gatsby';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { HeartWrapper } from '../HeartFavouriteFlats/HeartFavouriteFlats.styled';

export const BurgerMenuWrapper = styled.div`
    display: none;

    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 32px;
    }
`;

export const IconLine = styled.div`
    border-radius: 10px;
    height: 2px;
    transform-origin: 4.5px;
    transition: all 0.3s linear;
    width: 21px;
`;

export const BurgerButton = styled.button<{ isOpen: boolean }>(
    ({ theme: { colors }, isOpen }) => css`
        align-items: center;
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        pointer-events: all;
        z-index: 999;

        &:focus {
            outline: none;
        }

        ${IconLine} {
            background: ${isOpen ? colors.white : colors.neutral900};
            transform-origin: 1px;
            width: ${isOpen ? '25px' : '20px'};

            :first-child {
                transform: ${isOpen ? 'rotate(45deg)' : 'rotate(0)'};
            }

            :nth-child(2) {
                margin: 6px 0;
                opacity: ${isOpen ? 0 : 1};
                transform: ${isOpen ? 'translateX(-20px)' : 'translateX(0)'};
            }

            :nth-child(3) {
                transform: ${isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
            }
        }
    `,
);

export const BurgerMenuContent = styled.div<{ isOpen: boolean }>(
    ({ theme: { colors }, isOpen }) => css`
        background-color: ${colors.neutral900};
        height: 100%;
        left: 0;
        overflow: auto;
        padding: 24px;
        pointer-events: all;
        position: fixed;
        top: 0;
        transform: ${isOpen ? 'translateX(0)' : 'translateX(-100vw)'};
        transition: all 0.2s linear;
        width: 100vw;
        z-index: 998;
    `,
);

export const MobileLogo = styled.img`
    display: none;

    ${mediaQueries.md} {
        display: block;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
`;

export const NavLink = styled(Link)<{ isActive?: boolean }>(
    ({ theme: { colors, fontSizes, fontWeight }, isActive }) =>
        css`
            border-bottom: 4px solid
                ${isActive ? colors.primaryBlue500 : 'transparent'};
            color: ${colors.white};
            font-size: ${fontSizes.f24};
            font-weight: ${fontWeight.bold};
            line-height: 29px;
            margin: 8px 0;
            text-decoration: none;
            width: fit-content;
            padding-bottom: ${isActive ? '4px' : '0'};

            &:hover {
                color: ${colors.primaryBlue500};
            }
        `,
);

export const NavLinkfavorite = styled(NavLink)(
    ({ theme: { colors } }) =>
        css`
            display: flex;

            a {
                margin-left: 20px;
            }

            :hover {
                ${HeartWrapper} {
                    svg {
                        stroke: ${colors.primaryBlue500};
                    }
                }
            }
        `,
);

export const NavContainer = styled.nav`
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    width: 100%;
`;

export const KontaktWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 62px;
`;

export const KontaktLink = styled.a(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.white};
            display: flex;
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.medium};
            line-height: 18px;

            svg {
                fill: ${colors.primaryBlue50};
                margin-right: 10px;
            }

            &:hover {
                color: ${colors.primaryBlue500};

                path {
                    fill: ${colors.primaryBlue500};
                }
            }

            :nth-child(2) {
                margin: 16px 0;
            }
        `,
);
