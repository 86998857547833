import { Link } from 'gatsby';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            align-items: center;
            color: ${colors.neutral900};
            display: flex;
            justify-content: space-between;
            max-width: 100%;
            padding: 32px 136px;

            @media (max-width: 1435px) {
                padding: 32px 72px;
            }

            ${mediaQueries.md} {
                padding: 24px 16px;
            }
        `,
);

export const Logo = styled.img`
    display: block;
    width: 100%;

    ${mediaQueries.md} {
        display: none;
    }
`;

export const MobileLogo = styled.img`
    display: none;
    width: 100%;

    ${mediaQueries.md} {
        display: block;
        max-width: 119px;
    }
`;

export const SecondHeaderLogo = styled.img`
    margin-right: 42px;
    max-width: 143px;

    @media (max-width: 1300px) {
        display: none;
    }

    @media (max-width: 1200px) {
        display: block;
    }

    ${mediaQueries.xs} {
        display: none;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    max-width: 220px;
`;

export const BurgerWrapper = styled.div`
    display: flex;
`;
