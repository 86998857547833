import { createContext, useContext } from 'react';

export interface ThemeContextType {
    mobileMenuIsOpen: boolean;
    setMobileMenuIsOpen: (mobileMenuIsOpen: boolean) => void;
    liked: string[];
    setLiked: (ids: string[]) => void;
    tooltipActive: boolean;
    setTooltipActive: (tooltipActive: boolean) => void;
    building: string[];
    setBuilding: Function;
    floor: string[];
    setFloor: Function;
    room: string[];
    setRoom: Function;
    yardage: string[];
    setYardage: Function;
    price: string[];
    setPrice: Function;
    facilities: string[];
    setFacilities: Function;
    sortBy: string;
    setSortBy: Function;
    status: string[];
    setStatus: Function;
    isInitial: boolean;
    setIsInitial: Function;
    numberFilter: number;
    setNumberFilter: Function;
    activeFilter: boolean;
    setActiveFilter: Function;
    partnersPageVisible?: boolean;
    setPartnersPageVisible: Function;
    disabledPages?: string[];
    setDisabledPages: Function;
}

export const ThemeContext = createContext<ThemeContextType>({
    mobileMenuIsOpen: false,
    setMobileMenuIsOpen: () => undefined,
    liked: [],
    setLiked: () => undefined,
    tooltipActive: false,
    setTooltipActive: () => undefined,
    building: [],
    setBuilding: () => undefined,
    floor: [],
    setFloor: () => undefined,
    room: [],
    setRoom: () => undefined,
    yardage: [],
    setYardage: () => undefined,
    price: [],
    setPrice: () => undefined,
    facilities: [],
    setFacilities: () => undefined,
    sortBy: 'Domyślnie',
    setSortBy: () => undefined,
    status: [],
    setStatus: () => undefined,
    isInitial: true,
    setIsInitial: () => undefined,
    numberFilter: 0,
    setNumberFilter: () => undefined,
    activeFilter: false,
    setActiveFilter: () => undefined,
    partnersPageVisible: false,
    setPartnersPageVisible: () => undefined,
    disabledPages: [],
    setDisabledPages: () => undefined,
});

export const useTheme = () => useContext(ThemeContext);
