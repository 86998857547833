import React from 'react';
import { NavContainer, NavLink } from './Navigation.styled';
import { useTheme } from 'layout/ThemeContext';
import { getMenuLinks } from 'utils/utils';

const isBrowser = typeof window !== 'undefined';

export interface NavigationProps {
    investmentSlug?: string;
    menuPosition: string;
}

export const Navigation = ({
    investmentSlug,
    menuPosition,
}: NavigationProps) => {
    const { disabledPages } = useTheme();

    const isCurrent = (name: string[]) => {
        if (isBrowser) {
            const path = window.location.pathname.split('/');
            return name.includes(path[2]);
        }
    };

    return (
        <NavContainer isRight={menuPosition === 'right'}>
            {getMenuLinks(disabledPages).map(({ key, link, name }, index) => {
                return (
                    <NavLink
                        isActive={isCurrent(key)}
                        key={index}
                        to={'/' + investmentSlug + link}
                    >
                        {name}
                    </NavLink>
                );
            })}
        </NavContainer>
    );
};
