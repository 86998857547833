export const Links = [
    {
        link: '/o-inwestycji',
        name: 'O inwestycji',
        key: ['o-inwestycji'],
    },
    {
        link: '/mieszkania',
        name: 'Mieszkania',
        key: ['mieszkania', 'mieszkanie', 'garaze'],
    },
    {
        link: '/lokalizacja',
        name: 'Lokalizacja',
        key: ['lokalizacja'],
    },
    { link: '/galeria', name: 'Galeria', key: ['galeria'] },
    {
        link: '/promocje',
        name: 'Promocje',
        key: ['promocje'],
    },
    {
        link: '/bezpieczny-kredyt',
        name: 'BK2%',
        key: ['bezpieczny-kredyt'],
    },
    {
        link: '/partnerzy',
        name: 'Partnerzy',
        key: ['partnerzy'],
    },
    { link: '/kontakt', name: 'Kontakt', key: ['kontakt'] },
];
